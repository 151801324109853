import {
  IBoxButtonMessages,
  IBoxButtonSettings,
} from "@ecp-boxes/settingsPatterns/boxes/BoxButton/StyledButton.types";
import { IComparisonSetting } from "@ecp-boxes/settingsPatterns/contentPatterns.scheme";
import { useOpenPortalSnackbar } from "@ecp-boxes/shared/components/Snackbar/Snackbar";
import { useMessagesSettingsContext } from "@ecp-boxes/structure/Contexts/MessagesSettingsContext";
import * as productCompareApi from "@ecp-redux/api/productCompare";

const useCompareActionButton = (
  isCompared: boolean,
  sku?: string,
  comparisonSetting?: IComparisonSetting
) => {
  const { messages, settings } = useMessagesSettingsContext<
    IBoxButtonMessages,
    IBoxButtonSettings
  >();
  const { openPortalSnackbar } = useOpenPortalSnackbar();

  const [addToProductCompare] =
    productCompareApi.usePostAddProductCompareMutation();
  const [removeFromProductCompare] =
    productCompareApi.usePutRemoveProductCompareMutation();

  const getCurrentLabel = () => {
    if (!comparisonSetting || !sku) return null;

    return comparisonSetting[
      isCompared ? "removeButtonLabel" : "addButtonLabel"
    ];
  };

  const getCurrentButtonStyle = () => {
    if (!comparisonSetting || !sku) return null;

    return comparisonSetting[
      isCompared ? "removeButtonStyle" : "addButtonStyle"
    ];
  };

  const handleOnClick = () => {
    if (!sku) return null;

    if (isCompared) {
      removeFromProductCompare(sku)
        .unwrap()
        .then(() => {
          openPortalSnackbar(
            settings.remove_compare_product_snackbar,
            messages.remove_compare_product_snackbar
          );
        });
    } else {
      addToProductCompare(sku)
        .unwrap()
        .then(() => {
          openPortalSnackbar(
            settings.add_compare_product_snackbar,
            messages.add_compare_product_snackbar
          );
        })
        .catch(() => {
          openPortalSnackbar(
            settings.error_message_snackbar,
            messages.error_message
          );
        });
    }
  };

  return {
    compareButton: {
      label: getCurrentLabel(),
      buttonStyle: getCurrentButtonStyle(),
      onClick: handleOnClick,
    },
  };
};

export default useCompareActionButton;

import { IIcon } from "./Icon.types";

interface IClipboardOff extends IIcon {
  testId: string;
}

const ClipboardOff: React.FC<IClipboardOff> = ({
  width = "24",
  height = "24",
  fill = "#000",
  viewBox = "4 4 24 24",
  onClick,
  className,
  testId,
}: IClipboardOff): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
      onClick={onClick}
      className={className}
      data-testid={testId}
    >
      <g clipPath="url(#clip0_12678_207651)">
        <path
          fill={fill}
          d="M15.58 25.702c.12.093.268.144.42.145.152 0 .3-.052.42-.145 3.823-2.97 6.456-5.528 8.299-8.052 2.351-3.224 2.888-6.202 1.595-8.85-.921-1.89-3.575-3.423-6.663-2.536A6.786 6.786 0 0016 8.856a6.786 6.786 0 00-3.651-2.592C9.255 5.363 6.607 6.91 5.686 8.8c-1.293 2.647-.757 5.624 1.595 8.85 1.843 2.523 4.476 5.08 8.3 8.051z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_12678_207651">
          <path fill="#fff" d="M0 0H32V32H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default ClipboardOff;

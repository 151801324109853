import { memo, useCallback } from "react";
import { useTheme } from "styled-components";
import { TSrpProductType } from "@ecp-redux/dto/searchResults.types";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { useWishList } from "../../../helpers/useWishList";
import ClipboardOn from "../../icons/ClipboardOff";
import ClipboardOff from "../../icons/ClipboardOn";
import {
  StyledClipBoardIconFromSettings,
  StyledClipboardIconContainer,
} from "./Clipboard.styled";
import { IClipboardIconProps } from "./ClipboardIcon.scheme";

const ClipboardIcon = ({
  isOnWishList,
  productData,
  className = "clipboard-icon",
  strokeColor,
}: IClipboardIconProps) => {
  const {
    advanceSettings: {
      settings: { clipboard_icon_on: iconOn, clipboard_icon_off: iconOff },
    },
  } = useTheme() as IThemeState;

  const { handleAddToWishList, handleRemoveFromWishList } =
    useWishList(productData);

  const getIconOn = useCallback(() => {
    return iconOn ? (
      <StyledClipBoardIconFromSettings
        src={iconOn}
        data-testid={`${className}-on`}
        className={`${className}-on`}
      />
    ) : (
      <ClipboardOn testId={`${className}-on`} className={`${className}-on`} />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iconOn]);

  const getIconOff = useCallback(() => {
    return iconOff ? (
      <StyledClipBoardIconFromSettings
        src={iconOff}
        data-testid={`${className}-off`}
        className={`${className}-off`}
      />
    ) : (
      <ClipboardOff
        strokeColor={strokeColor}
        testId={`${className}-off`}
        className={`${className}-off`}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iconOff]);

  const handleIconClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();
      isOnWishList ? handleRemoveFromWishList() : handleAddToWishList();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isOnWishList, productData.sku, productData.type],
  );

  if (productData.type === TSrpProductType.VARIANT) return null;

  return (
    <StyledClipboardIconContainer
      data-testid={`${className}-container`}
      onClick={handleIconClick}
    >
      {isOnWishList ? getIconOn() : getIconOff()}
    </StyledClipboardIconContainer>
  );
};

export default memo(ClipboardIcon);

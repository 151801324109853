import useAddToWishlistLogic from "@ecp-boxes/shared/hooks/useAddToWishlistLogic";
import { TRegularBoxType } from "@ecp-pageTypes";
import { ISrpTransformResponseV2 } from "@ecp-redux/dto/searchResults.types";
import { IElementButton } from "@ecp-redux/dto/themeSettings/settingsPatterns.types";
import { TButtonId } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import classNames from "classnames";
import LinkWrapper from "../../../../../../global/components/LinkWrapper/LinkWrapper";
import StyledButton from "../../../../../styleElements/StyledButton/StyledButton";
import AddToCart from "../../../Cart/AddToCart/AddToCart";
import { IProductButton, ProductButtonActions } from "../ProductModule.types";
import { StyledActionButton } from "./ActionButton.styled";
import useCompareActionButton from "./useCompareActionButton";

interface IActionButtonProps {
  buttonText: string;
  product: ISrpTransformResponseV2;
  buttonStyle: IElementButton | TButtonId;
  boxType: TRegularBoxType;
  isProductCompared?: boolean;
  buttonSettings?: IProductButton;
  className?: string;
}

const ActionButton: React.FC<IActionButtonProps> = ({
  buttonText,
  buttonStyle,
  product,
  buttonSettings,
  boxType,
  className,
  isProductCompared,
}) => {
  const isGoToUrl =
    buttonSettings?.type === ProductButtonActions.GO_TO_URL &&
    buttonSettings?.url !== undefined;
  const isGoToProduct =
    buttonSettings?.type === ProductButtonActions.GO_TO_PRODUCT && product?.url;
  const isComparison = buttonSettings?.type === ProductButtonActions.COMPARISON;
  const isAddToWishlist =
    buttonSettings?.type === ProductButtonActions.ADD_TO_WISHLIST;

  const { compareButton } = useCompareActionButton(
    isProductCompared ?? product?.isCompare,
    product?.sku,
    buttonSettings?.comparisonSetting
  );

  const { addToWishlistButton, addRemoveWishlistFunction } =
    useAddToWishlistLogic(product, buttonSettings?.addToWishlistSetting);

  return (
    <StyledActionButton
      className={classNames("actionButtons", className ?? "")}
    >
      {isGoToUrl && (
        <LinkWrapper
          href={buttonSettings.url}
          className="action-button-container__link-wrapper"
        >
          <StyledButton
            className="action-button-container__link-wrapper__go-to-url"
            role="button"
            $settings={buttonStyle}
            data-testid="go-to-url-button"
            renderAs={"button"}
          >
            {buttonText}
          </StyledButton>
        </LinkWrapper>
      )}
      {isGoToProduct && (
        <LinkWrapper
          href={product?.url}
          className="action-button-container__link-wrapper"
        >
          <StyledButton
            className="action-button-container__link-wrapper__go-to-product"
            role="button"
            $settings={buttonStyle}
            data-testid="go-to-product-button"
            renderAs={"button"}
          >
            {buttonText}
          </StyledButton>
        </LinkWrapper>
      )}
      {!isGoToProduct && !isGoToUrl && !isComparison && !isAddToWishlist && (
        <AddToCart
          itemId={product?.sku}
          buttonText={buttonText}
          buttonStyle={buttonStyle}
          boxType={boxType}
        />
      )}
      {isComparison && typeof buttonStyle !== "string" && (
        <StyledButton
          className="action-button-container__link-wrapper__compare-product"
          role="button"
          $settings={{ ...buttonStyle, ...compareButton.buttonStyle }}
          data-testid="compare-button"
          renderAs={"button"}
          onClick={compareButton.onClick}
        >
          {compareButton.label}
        </StyledButton>
      )}
      {isAddToWishlist && typeof buttonStyle !== "string" && (
        <StyledButton
          className="action-button-container__link-wrapper__add-to-wishlist-product"
          role="button"
          $settings={{
            ...buttonStyle,
            ...addToWishlistButton.buttonStyle,
          }}
          data-testid="add-to-wishlist-button"
          renderAs={"button"}
          onClick={() => {
            addRemoveWishlistFunction();
          }}
        >
          {addToWishlistButton.label}
        </StyledButton>
      )}
    </StyledActionButton>
  );
};

export default ActionButton;
